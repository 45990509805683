import {useRouter} from 'next/router'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'next-i18next'

import {IChartingLibraryWidget, LanguageCode} from '../../../public/static/charting_library'
import {TTradingViewChartType} from '../component/types/chart'

import {createDatafeed} from '../utils/dataFeed'

import {createChartMarks, createTvWidget} from '../utils/chartUtils'
import {getTVWidgetThemeOptions, WIDGET_OPTIONS} from '../constants/chartConfig'

import {showToast} from '@component/snackbar/WizToastMessage'

import useCheckOrderSign from './useCheckOrderSign'
import {useQueryGetBinanceSymbols} from './useQueryGetBinanceSymbols'
import {useQueryGetUpbitSymbols} from './useQueryGetUpbitSymbols'
import {useBinanceExchangeData} from './useBinanceExchangeData'
import {useUpbitExchangeData} from './useUpbitExchangeData'
import {useOrderAlertStore} from './useOrderAlertStore'
import {useChartSettingsStore} from './useChartSettingsStore'
import {DarkModeStore} from '@store/DarkmodeStore'

export const useTradingViewChart = (
    kind: TTradingViewChartType,
    chartContainerRef: React.MutableRefObject<HTMLDivElement>,
    enable: boolean = true,
) => {
    const {t} = useTranslation()
    const router = useRouter()
    const [tvWidget, setTvWidget] = useState<IChartingLibraryWidget>()

    const {data: symbols} = useQueryGetBinanceSymbols(false)
    // const {data: upbitSymbols} = useQueryGetUpbitSymbols()

    const {binanceDatafeed} = useBinanceExchangeData()
    // const {upbitDatafeed} = useUpbitExchangeData()

    const isDark = DarkModeStore(state => state.isDark)
    const {order, setOrder} = useOrderAlertStore()
    const {symbol: selectedSymbol, interval, setSymbol, setInterval, onSearchSymbolComplete} = useChartSettingsStore()
    const {enableOrderSign} = useCheckOrderSign()

    const datafeed = useMemo(() => {
        if (!chartContainerRef?.current || !symbols || !binanceDatafeed) return
        return createDatafeed(binanceDatafeed, kind, symbols, setInterval)
    }, [chartContainerRef.current, kind, symbols, binanceDatafeed])

    const clearTVWidget = useCallback(() => {
        if (!tvWidget) return
        tvWidget?.remove()
        setTvWidget(undefined)
    }, [tvWidget])

    const onCreateTVWidget = useCallback(() => {
        clearTVWidget()
        setTvWidget(
            createTvWidget({
                ...WIDGET_OPTIONS,
                ...getTVWidgetThemeOptions(isDark),
                container: chartContainerRef?.current,
                locale: router?.locale as LanguageCode,
                timezone: router?.locale === 'ko' ? 'Asia/Seoul' : Intl.DateTimeFormat().resolvedOptions().timeZone,
                interval,
                datafeed,
                symbol: selectedSymbol.base_symbol + selectedSymbol.quote_symbol,
                symbol_search_complete: onSearchSymbolComplete,
            }),
        )
    }, [router?.locale, datafeed, selectedSymbol, isDark, enable, interval, onSearchSymbolComplete, clearTVWidget])

    const onCheckSymbolList = useCallback((): boolean => {
        const hasSymbol = symbols?.some(({name}) => name === selectedSymbol.base_symbol + selectedSymbol.quote_symbol)

        if (!hasSymbol) {
            showToast(t('chat.chart.not.support.message'))
        }
        return hasSymbol
    }, [symbols, selectedSymbol])

    useEffect(() => {
        if (!datafeed || !enable) return
        if (!onCheckSymbolList()) return

        onCreateTVWidget()

        return () => clearTVWidget()
    }, [datafeed, enable, router?.locale, selectedSymbol, isDark])

    useEffect(() => {
        if (!enableOrderSign || !order || !tvWidget) return

        void createChartMarks(tvWidget, order, isDark, selectedSymbol)
        setOrder(undefined)
    }, [tvWidget, order, selectedSymbol, enableOrderSign])

    return {clearTVWidget, onCreateTVWidget}
}

import React from 'react'

import Text from '@component/Text/Text'

interface IProps {
    className?: string
    textClassName?: string
    text: string
    children?: React.ReactNode
}

const ChatMenuItem: React.FC<IProps> = ({
    className,
    text,
    children,
    textClassName = 'text-body3 text-gray02 dark:text-dark_gray02 group-hover:text-primary_shade dark:group-hover:text-dark_primary_shade',
}) => {
    return (
        <div className={`flex items-center gap-x-[7px] px-[14px] group ${className}`}>
            {children}
            <Text className={textClassName}>{text}</Text>
        </div>
    )
}
export default React.memo(ChatMenuItem)
